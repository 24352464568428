<template>
  <!--begin::Part Listing-->
  <div class="product-template">
    <!-- <PageHeaderCount
      moduleType="certificate"
      :dataLoading="dataLoading"
      :statusList="statusList"
      :extraList="topExtraList"
      allkey="all_parts"
      countkey="part_status_count"
    ></PageHeaderCount> -->
    <!-- <v-col
      v-if="alphabets.length"
      md="12"
      class="alphabets-filter d-flex justify-center"
    >
      <div class="alphabets-scrollable">
        <v-btn
          :disabled="searching"
          depressed
          v-on:click="getLineItem(null, 'alphabet')"
          :class="{
            'cyan--text': null == alphabet,
            'cyan white--text': null != alphabet,
          }"
          class="custom-bold-button"
          small
          >All</v-btn
        >
        <v-btn
          :disabled="searching"
          depressed
          v-for="(row, index) in alphabets"
          v-on:click="getLineItem(row, 'alphabet')"
          :class="{
            'cyan--text': row == alphabet,
            'cyan white--text': row != alphabet,
          }"
          class="custom-bold-button"
          small
          :key="index"
          >{{ row }}
          <v-badge
            bordered
            color="error"
            class="custom-margin"
            overlap
            dot
            offset-x="10"
            offset-y="10"
          >
          </v-badge>
        </v-btn>
      </div>
    </v-col> -->
    <ListingTemplate
      :customClass="'product-listing'"
      v-if="getPermission('part:view')"
    >
      <template v-slot:body>
        <ListingHeader>
          <template v-slot:content>
            <template v-if="selectedRows.length > 0">
              <v-flex class="pt-0">
                <h1
                  class="form-title custom-nowrap-ellipsis margin-auto selected-rows-text"
                >
                  {{ totalSelected }} Selected
                </h1>
              </v-flex>
              <v-flex class="pt-0 justify-flex-end d-flex margin-auto">
                <template v-if="getPermission('part:update')">
                  <v-menu
                    content-class="custom-menu-list"
                    bottom
                    offset-y
                    :close-on-click="true"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :disabled="dataLoading"
                        class="mx-2 custom-grey-border custom-bold-button"
                        color="cyan white--text"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon dark left>mdi-chevron-down</v-icon>Bulk Action
                      </v-btn>
                    </template>
                    <v-list>
                      <template v-for="(item, index) in bulkActions">
                        
                        <v-list-item
                          link
                          v-on:click="partBulkAction(item.status,item.action)"
                          :key="index"
                        >
                          <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                            <v-icon class="icon-default-blue">{{
                              item.icon
                            }}</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title
                            class="font-weight-500 font-size-14"
                            >{{ item.title }}</v-list-item-title
                          >
                        </v-list-item>
                      </template>
                    </v-list>
                  </v-menu>
                </template>
                <v-btn
                  :disabled="dataLoading"
                  class="mx-2 custom-grey-border custom-bold-button"
                  color="red lighten-1 white--text"
                  v-on:click="clearSelections"
                >
                  <v-icon dark left>mdi-close</v-icon>Clear Selections
                </v-btn>
              </v-flex>
            </template>
            <template v-else>
              <v-flex class="pt-0">
                <v-layout class="w-100">
                  <v-flex class="mxw-177">
                    <h1 class="form-title d-flex margin-auto">
                      <v-select
                        :disabled="dataLoading"
                        :items="statusList"
                        v-model="status"
                        hide-details
                        item-color="cyan"
                        class="pt-0 mt-0 listing-status main-listing-status-filter"
                        item-text="text"
                        item-value="value"
                      >
                        <template v-slot:item="{ item }">
                          <v-list-item-content>
                            <v-list-item-title
                              v-text="item.text"
                            ></v-list-item-title>
                            <v-list-item-subtitle
                              v-text="item.description"
                            ></v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-chip
                              :color="item.color"
                              :text-color="item.textcolor"
                            >
                              <template v-if="item.value == 'all'">{{
                                item.certificate_status_count
                              }}</template>
                              <template v-else>{{
                                item.certificate_status_count
                              }}</template>
                            </v-chip>
                          </v-list-item-action>
                        </template>
                      </v-select>
                    </h1>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex class="pt-0 justify-flex-end d-flex margin-auto">
                <template v-if="getPermission('part:create')">
                  <!-- custom-btn-color -->
                  <v-btn
                    :disabled="dataLoading"
                    class="mx-2 custom-bold-button white--text"
                    style="background-color: #f7941e"
                    :to="getDefaultRoute('certificate.create')"
                  >
                    <v-icon dark left>mdi-plus</v-icon> Create
                  </v-btn>
                </template>
                <template v-if="getPermission('part:create')">
                  <v-btn
                    v-if="false"
                    :disabled="dataLoading"
                    class="mx-2 custom-bold-button"
                    v-on:click="importDialog = true"
                    color="cyan white--text"
                  >
                    <v-icon dark left>mdi-database-import</v-icon> Import
                  </v-btn>
                </template>
                <v-btn
                  v-if="true"
                  :disabled="dataLoading"
                  class="mx-2 custom-bold-button"
                  v-on:click="exportQuotation"
                  color="cyan white--text"
                >
                  <v-icon dark left>mdi-database-export</v-icon> Export
                </v-btn>
                <!-- <template v-if="getPermission('part:create')">
                  <v-btn
                    v-if="true"
                    :disabled="dataLoading"
                    class="mx-2 custom-bold-button"
                    v-on:click="dialog = !dialog"
                    color="cyan white--text"
                  >
                    <v-icon dark left>print</v-icon> Print
                  </v-btn>
                </template> -->
                <v-btn
                  :disabled="dataLoading"
                  class="mx-2 custom-grey-border custom-bold-button"
                  color="cyan white--text"
                  v-on:click="searchDialog = true"
                >
                  <v-icon dark>mdi-filter</v-icon>
                </v-btn>
                <v-btn
                  v-if="false && !isEngineerChannel()"
                  v-on:click="
                    $router.push(
                      getDefaultRoute('profile.setting', {
                        query: {
                          tab: 'product',
                        },
                      })
                    )
                  "
                  :disabled="dataLoading"
                  class="mx-2 custom-grey-border custom-bold-button"
                  color="cyan white--text"
                >
                  <v-icon dark>mdi-cog</v-icon>
                </v-btn>
                <!-- <v-menu content-class="custom-menu-list" offset-y left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :disabled="dataLoading || exportLoading"
                      class="mx-2 custom-bold-button"
                      color="cyan white--text"
                      v-bind="attrs"
                      v-on="on"
                      :loading="exportLoading"
                    >
                      <v-icon dark>mdi-menu</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <template v-for="(item, index) in moreActions">
                      <v-list-item
                        :key="index"
                        link
                        :class="{ 'line-active': item.sort }"
                        v-on:click="moreAction(item.action, item.sort)"
                      >
                        <v-list-item-title>
                          <v-icon v-if="item.icon" small left color="cyan">{{
                            item.icon
                          }}</v-icon>
                          {{ item.title }}
                          <template v-if="item.sort">
                            <v-icon
                              v-if="item.sort == 'asc'"
                              small
                              right
                              color="cyan"
                              >mdi-sort-ascending</v-icon
                            >
                            <v-icon
                              v-if="item.sort == 'desc'"
                              small
                              right
                              color="cyan"
                              >mdi-sort-descending</v-icon
                            >
                          </template>
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-list>
                </v-menu> -->
                <v-menu
                  max-height="400"
                  max-width="250"
                  offset-y
                  left
                  :close-on-content-click="false"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :disabled="dataLoading"
                      class="mx-2 custom-bold-button"
                      color="cyan white--text"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon dark>mdi-table-edit</v-icon>
                    </v-btn>
                  </template>
                  <draggable
                    tag="ul"
                    v-model="defaultColDefs"
                    class="draggable-group"
                    handle=".draggable-drag-icon"
                    v-on:change="updateTable('certificate')"
                  >
                    <template v-for="cols in defaultColDefs">
                      <li
                        class="draggable-group-item"
                        :key="cols.field"
                        v-if="!cols.checkbox || Number(cols.checkbox) == 0"
                      >
                        <v-checkbox
                          dense
                          v-model="defaultColShow"
                          v-bind:value="cols.field"
                          v-on:change="updateVisible()"
                          :label="cols.headerName"
                          :disabled="Number(cols.fixed) || dataLoading"
                          color="cyan"
                          hide-details
                          class="pt-2 mt-0"
                        ></v-checkbox>
                        <v-icon
                          class="draggable-drag-icon"
                          small
                          right
                          color="cyan"
                          >mdi-drag</v-icon
                        >
                      </li>
                    </template>
                  </draggable>
                </v-menu>
                <PageTips v-if="false" module="product"></PageTips>
              </v-flex>
            </template>
          </template>
        </ListingHeader>
        <ListingSearchCriteria
          v-if="searchEnabled"
          pageModule="Certificates"
          :basicSearchFields="['customer name', 'certificate no', 'serial no']"
          :allowedSearchFields="searchableArray"
        ></ListingSearchCriteria>
        <perfect-scrollbar
          :options="{ suppressScrollX: true }"
          class="scroll custom-box-top-inner-shadow"
          style="max-height: 90vh; position: relative"
        >
          <ListingTable
            :columnCount="columnLength()"
            :dataLoading="dataLoading"
            :rowData="rowData"
          >
            <template v-slot:thead>
              <thead>
                <tr>
                  <template v-for="cols in customThead">
                    <th
                      v-if="cols.visible"
                      :key="cols.id"
                      class="simple-table-th"
                      :class="{ 'checkbox-width-limit': cols.checkbox }"
                      :style="{ 'max-width': cols.width }"
                    >
                      <template
                        v-if="cols.checkbox && Number(cols.checkbox) == 1"
                      >
                        <v-checkbox
                          dense
                          :disabled="dataLoading"
                          v-model="selectedAll"
                          color="cyan"
                          class="hide-margin"
                          hide-details
                        ></v-checkbox>
                      </template>
                      <template v-else>
                        <div
                          v-if="cols.sortable"
                          class="simple-table-text sortable"
                          v-on:click="
                            !dataLoading
                              ? updateSorting({
                                  field: cols.field,
                                  sort: cols.sort,
                                })
                              : 'javascript:void(0)'
                          "
                        >
                          {{ cols.headerName }}
                        </div>
                        <div v-else class="simple-table-text">
                          {{ cols.headerName }}
                        </div>
                        <template v-if="cols.sort">
                          <v-icon
                            class="simple-table-sort"
                            v-if="cols.sort == 'asc'"
                            small
                            right
                            color="cyan"
                            >mdi-sort-ascending</v-icon
                          >
                          <v-icon
                            class="simple-table-sort"
                            v-if="cols.sort == 'desc'"
                            small
                            right
                            color="cyan"
                            >mdi-sort-descending</v-icon
                          >
                        </template>
                      </template>
                    </th>
                  </template>
                </tr>
              </thead>
            </template>
            <template v-slot:tbody>
              <tbody class="custom-border-bottom">
                <!-- :class="{ 'table-in-active-row': !data.activated }" -->
                <template v-if="rowData.length > 0">
                  <tr
                    class="product-listing-row"
                    v-for="(data, index) in rowData"
                    :key="index"
                    link
                  >
                    <template v-for="cols in customThead">
                      <td
                        v-if="cols.visible"
                        :key="cols.id"
                        :class="{
                          'simple-table-td': !cols.checkbox,
                          'checkbox-width-limit': cols.checkbox,
                        }"
                        :style="{ 'max-width': cols.width }"
                        v-on:click="
                          Number(cols.checkbox) == 0
                            ? rowClicked(data)
                            : 'javascript:void(0)'
                        "
                      >
                        <template
                          v-if="cols.checkbox && Number(cols.checkbox) == 1"
                        >
                          <v-checkbox
                            dense
                            v-model="selectedRows"
                            v-bind:value="data.id"
                            color="cyan"
                            class="hide-margin"
                            hide-details
                          ></v-checkbox>
                        </template>
                        <template v-else-if="cols.field == 'certificate_no'">
                          <Barcode
                            class="cursor-pointer"
                            route="certificate.detail"
                            :barcode="data.barcode"
                          ></Barcode>
                          <p v-if="data.status_value"><Barcode
                            class="cursor-pointer"
                            :barcode="data.status_value"
                            :chipColor="getStatusColor(data.status_value)"
                          ></Barcode></p>
                        </template>
                        <template v-else-if="cols.field == 'equipment_id'">
                          <template v-if="data.equipment_id">
                            <Barcode
                              class="cursor-pointer"
                              route="certificate.detail"
                              :barcode="data.equipment_id"
                            ></Barcode><br>
                            <span v-if="data.equipment_type">Equipment type: {{ data.equipment_type }}</span>
                          </template>
                          <em v-else>no equipment</em>
                        </template>
                        <template v-else-if="cols.field == 'serial_no'">
                          <p class="m-0">
                            <template >
                              
                                <span style="font-weight: 600; color: black" v-if="data.serial_no">
                                {{ data.serial_no }}
                                </span>
                                <em v-else> no serial no.</em>
                            </template>
                            
                          </p>
                        </template>
                        <template v-else-if="cols.field == 'customer'">
                          <p class="m-0">
                            <template v-if="data.customer_name">
                              <span style="font-weight: 600; color: black">
                                {{ data.customer_name }}
                              </span>
                            </template>
                            <em v-else> no customer name</em>
                          </p>
                        </template>

                        <template v-else-if="cols.field == 'quotation'">
                          <p class="m-0">
                            <template v-if="data.quotation_no">
                              <span style="font-weight: 600; color: black">
                                {{ data.quotation_no }}
                              </span>
                            </template>
                            <em v-else> no quotation number</em>
                          </p>
                        </template>

                        <template v-else-if="cols.field == 'job_order_no'">
                          <p class="m-0">
                            <template v-if="data.job_no">
                              <span style="font-weight: 600; color: black">
                                {{ data.job_no }}
                              </span>
                            </template>
                            <em v-else> no job number</em>
                          </p>
                        </template>

                        <template v-else-if="cols.field == 'created_time'">
                          <p class="m-0">
                            <template v-if="data.added_at">
                              <div>
                                <template v-if="data.addedBy">
                                  <Barcode
                                    class="cursor-pointer ma-2"
                                    :barcode="data.addedBy"
                                  ></Barcode>
                                </template>
                              </div>
                              <span>
                                <v-chip
                                  class="ma-2"
                                  text-color="white"
                                  color="rgb(46 39 39 / 84%)"
                                  style="font-size: 15px"
                                >
                                  <v-avatar left>
                                    <v-icon>mdi-clock</v-icon>
                                  </v-avatar>
                                  {{ data.created_at }}
                                </v-chip>
                              </span>
                             
                            </template>
                            <em v-else> no created time</em>
                          </p>
                        </template>

                        <template v-else-if="cols.field == 'duplicate'">
                          <!-- <p  v-bind="attrs"
                                v-on="on">
                          <v-icon color="cyan">content_copy</v-icon></p> -->
                          <v-tooltip top small class="p-0" color="grey">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon v-bind="attrs" v-on="on" color="cyan"
                                >content_copy</v-icon
                              >
                            </template>
                            <span class="font-size-10">Duplicate</span>
                          </v-tooltip>
                        </template>

                        <template v-else>
                          <div v-html="printRowData(cols, data)"></div>
                        </template>
                      </td>
                    </template>
                  </tr>
                </template>
                <tr v-else-if="!dataLoading">
                  <td colspan="7" class="py-4">
                    <p
                      class="m-0 row-not-found text-center font-weight-500 font-size-16"
                    >
                      <img
                        :src="$assetURL('media/error/empty.png')"
                        class="row-not-found-image"
                      />
                      Uhh... There are no certificates at the moment.
                    </p>
                  </td>
                </tr>
              </tbody>
            </template>
          </ListingTable>
        </perfect-scrollbar>
        <ListingFooter
        :dataLoading="dataLoading"
          :showingFrom="showingFrom && showingFrom"
          :showingTo="showingTo && showingTo"
          :totalRows="totalRows && totalRows"
          :currentPage="currentPage"
          :totalPages="totalPages && totalPages"
        ></ListingFooter>
        <Dialog :commonDialog="searchDialog">
          <template v-slot:title>Filter Certificate</template>
          <template v-slot:body>
            <perfect-scrollbar
              :options="{ suppressScrollX: true }"
              class="scroll custom-box-top-inner-shadow"
              style="max-height: 90vh; position: relative"
            >
              <v-container fluid class="pt-0 custom-search-filter">
                <v-row>
                  <!-- <template v-for="(field, index) in searchableArray">
                    <v-col md="6" :key="index" v-if="field == 'is_low_stock'">
                      <v-checkbox
                        class="mt-0 width-100"
                        color="cyan"
                        v-model.trim="listingSearch.is_low_stock"
                        @change="filterRows"
                      >
                        <template v-slot:label>
                          <label class="custom-form-label m-0 width-100"
                            >Low Stock</label
                          >
                        </template>
                      </v-checkbox>
                    </v-col>
                    <v-col md="6" :key="index" v-else-if="field != 'dates'">
                      <label
                        class="font-weight-700 font-size-16 text-capitalize"
                        >{{ getFilterLabel(field, "Certificate No.") }}</label
                      >
                      <v-text-field
                        v-model.trim="listingSearch[field]"
                        dense
                        filled
                        hide-details
                        :label="getFilterLabel(field, 'Certificate No.')"
                        solo
                        flat
                        clearable
                        @keydown.enter="filterRows"
                        color="cyan"
                      ></v-text-field>
                    </v-col>
                  </template> -->
                  <template>
                    <v-col md="6">
                      <label
                        class="font-weight-700 font-size-16 text-capitalize"
                        >Certificate No. #</label
                      >
                      <v-text-field
                        v-model.trim="listingSearch['certificate_number']"
                        dense
                        filled
                        hide-details
                        label="Certificate No. #"
                        class="px-0"
                        solo
                        flat
                        clearable
                        color="cyan"
                      ></v-text-field>
                    </v-col>

                    <v-col md="6">
                      <label
                        class="font-weight-700 font-size-16 text-capitalize px-1"
                        >Serial no.</label
                      >
                      <v-text-field
                        v-model.trim="listingSearch['serial_no']"
                        dense
                        filled
                        hide-details
                        label="Serial No."
                        class="px-1"
                        solo
                        flat
                        clearable
                        color="cyan"
                      ></v-text-field>
                    </v-col>

                    <v-col md="6">
                      <label
                        class="font-weight-700 font-size-16 text-capitalize"
                        >Customer Name</label
                      >
                      <v-text-field
                      v-model.trim="listingSearch['customer_name']"
                        dense
                        filled
                        hide-details
                        label="Customer Name"
                        class="px-0"
                        solo
                        flat
                        clearable
                        color="cyan"
                      ></v-text-field>
                    </v-col>
                    <v-col md="6">
                      <label
                        class="font-weight-700 font-size-16 text-capitalize"
                        >Calibration Date</label
                      >
                      <Datepicker
                        v-model.trim="listingSearch['calibration_date']"
                        solo
                        flat
                        class="px-0"
                        :default-date="defaultDate"
                        :clearable="true"
                      ></Datepicker>
                    </v-col>
                    <v-col md="6">
                      <label
                        class="font-weight-700 font-size-16 text-capitalize px-1"
                        >Job Order No.</label
                      >
                      <v-text-field
                        v-model.trim="listingSearch['job_order_no']"
                        dense
                        filled
                        hide-details
                        label="Job Order No."
                        class="px-1"
                        solo
                        flat
                        clearable
                        color="cyan"
                      ></v-text-field>
                    </v-col>
                  </template>
                </v-row>
              </v-container>
            </perfect-scrollbar>
          </template>
          <template v-slot:action>
            <v-btn
              :disabled="dataLoading"
              class="mx-2 custom-grey-border custom-bold-button"
              color="cyan white--text"
              v-on:click="filterRows"
            >
              Filter
            </v-btn>
            <v-btn
              :disabled="dataLoading"
              class="mx-2 custom-grey-border custom-bold-button"
              v-on:click="searchDialog = false"
            >
              Close
            </v-btn>
          </template>
        </Dialog>

        <Dialog :common-dialog="dialog" :dialogWidth="450">
          <template v-slot:title>
            <span style="font-size: 18px; font-weight: 800"
              >Print Certificate Label</span
            >
          </template>
          <template v-slot:body>
            <div class="mx-4">
              <p style="font-weight: 700; font-size: 16px" class="">
                Are you sure you want to print?
              </p>
            </div>
            <!-- <v-row class="">
              <v-radio-group v-model="row" row style="margin-left: 20px;">
                <v-radio color="cyan" label="Yes" value="yex"></v-radio>
                <v-radio color="cyan" label="No" value="no"></v-radio>
              </v-radio-group>
            </v-row> -->
          </template>
          <template v-slot:action>
            <v-btn
              :disabled="!formValid || formLoading || pageLoading"
              :loading="formLoading"
              class="mx-2 custom-grey-border custom-bold-button white--text"
              color="red"
              v-on:click="dialog = false"
            >
              Cancel
            </v-btn>
            <v-btn
              :disabled="!formValid || formLoading || pageLoading"
              :loading="formLoading"
              class="mx-2 custom-grey-border custom-bold-button white--text"
              color="cyan"
              v-on:click="printLabel"
            >
              Print
            </v-btn>
          </template>
        </Dialog>
        <template v-if="getPermission('part:create')">
          <Dialog :commonDialog="importDialog">
            <template v-slot:title>Import Part</template>
            <template v-slot:body>
              <perfect-scrollbar
                :options="{ suppressScrollX: true }"
                class="scroll custom-box-top-inner-shadow"
                style="max-height: 90vh; position: relative"
              >
                <v-container fluid class="pt-0 custom-search-filter">
                  <v-row>
                    <template>
                      <a href="./../Sample/product.xlsx" download>
                        <v-btn
                          :disabled="dataLoading"
                          class="mx-2 custom-grey-border custom-bold-button"
                          color="cyan white--text"
                        >
                          Download Sample
                        </v-btn></a
                      >
                    </template>
                  </v-row>
                  <v-row>
                    <template>
                      <div class="container">
                        <form enctype="multipart/form-data" method="POST">
                          <div class="large-4 medium-4 small-4 cell">
                            <label>
                              <input
                                type="file"
                                id="file"
                                ref="file"
                                v-on:change="handleFileUpload()"
                              />
                            </label>
                          </div>
                        </form>
                      </div>
                    </template>
                  </v-row>
                </v-container>
              </perfect-scrollbar>
            </template>
            <template v-slot:action>
              <v-btn
                :disabled="dataLoading"
                class="mx-2 custom-grey-border custom-bold-button"
                color="cyan white--text"
                v-on:click="importRows"
              >
                Import
              </v-btn>
              <v-btn
                :disabled="dataLoading"
                class="mx-2 custom-grey-border custom-bold-button"
                v-on:click="importDialog = false"
              >
                Close
              </v-btn>
            </template>
          </Dialog>
        </template>
      </template>
    </ListingTemplate>
  </div>
  <!--end::Product Listing-->
</template>

<script>
import draggable from "vuedraggable";
import CommonMixin from "@/core/plugins/common-mixin";
import ListingMixin from "@/core/plugins/listing-mixin";

import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Barcode from "@/view/pages/partials/Barcode.vue";
// import TableActivity from "@/view/pages/partials/Table-Activity.vue";
import ListingTemplate from "@/view/pages/partials/Listing-Template.vue";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import ListingHeader from "@/view/pages/partials/Listing-Header.vue";
import ListingFooter from "@/view/pages/partials/Listing-Footer.vue";
import ListingSearchCriteria from "@/view/pages/partials/Listing-Search-Criteria.vue";
import Dialog from "@/view/pages/partials/Dialog.vue";
import { DOWNLOAD, POST, PATCH } from "@/core/services/store/request.module";
import { saveAs } from "file-saver";
import PageTips from "@/view/layout/extras/offcanvas/PageTips.vue";
import Datepicker from "@/view/pages/partials/Datepicker.vue";
import moment from "moment-timezone";
// import PageHeaderCount from "@/view/pages/partials/PageHeaderCount.vue";
import JwtService from "@/core/services/jwt.service";

export default {
  mixins: [CommonMixin, ListingMixin],
  name: "certificate-list",
  data() {
    return {
      defaultColDefs:[],
      dataLoading: false,
      column: null,
      row: null,
      exportLoading: false,
      searching: false,
      pageLoading: false,
      pageModule: "product-listing",
      routeAPI: "certificate",
      routeName: "certificate",
      routeDetailName: "certificate.detail",
      status: "all",
      file: "",
      dialog: false,
      importDialog: false,
      statusList: [],
      customThead: [],
      defaultDate: moment().startOf("day").format("YYYY-MM-DD"),
      // custom_certificateThead: [
      //   {
      //     id: "77",
      //     type: "certificate",
      //     field: "id",
      //     headerName: "",
      //     checkbox: "1",
      //     visible: "1",
      //   },
      //   {
      //     id: "78",
      //     type: "certificate",
      //     field: "certificateno",
      //     headerName: "Certificate No. #",
      //     checkbox: "0",
      //     visible: "1",
      //   },
      //   {
      //     id: "79",
      //     type: "certificate",
      //     field: "barcode",
      //     headerName: "Serial Number",
      //     checkbox: "0",
      //     visible: "1",
      //   },
      //   {
      //     id: "80",
      //     type: "certificate",
      //     field: "part_info",
      //     headerName: "Name of Customer",
      //     checkbox: "0",
      //     visible: "1",
      //   },
      //   {
      //     id: "81",
      //     type: "certificate",
      //     field: "updated_at",
      //     headerName: "Calibration Schedule",
      //     checkbox: "0",
      //     visible: "1",
      //   },
      //   {
      //     id: "82",
      //     type: "certificate",
      //     field: "dimensions",
      //     headerName: "Calibration Date",
      //     checkbox: "0",
      //     visible: "1",
      //   },
      //   {
      //     id: "83",
      //     type: "certificate",
      //     field: "price",
      //     headerName: "Due Date",
      //     checkbox: "0",
      //     visible: "1",
      //   },
      //   {
      //     id: "84",
      //     type: "certificate",
      //     field: "create_Time",
      //     headerName: "Created Time",
      //     checkbox: "0",
      //     visible: "1",
      //   },
      //   {
      //     id: "85",
      //     type: "certificate",
      //     field: "duplicate",
      //     headerName: "Action",
      //     checkbox: "0",
      //     visible: "1",
      //   },
      // ],
      rowData_list: [
      ],
      categoryList: [],
      allowedSearchFields: [
        "certificate_no",
        "serial_no",
        "customer_name",
        "calibration_schedule",
        "calibration_date",
        "due_date",
        "create_time",
      ],
      alphabets: [
        "a",
        "b",
        "c",
        "d",
        "e",
        "f",
        "g",
        "h",
        "i",
        "j",
        "k",
        "l",
        "m",
        "n",
        "o",
        "p",
        "q",
        "r",
        "s",
        "t",
        "u",
        "v",
        "w",
        "x",
        "y",
        "z",
      ],
      moreActions: [
        {
          title: "Refresh List",
          action: "refresh_list",
          divider: true,
          icon: "mdi-refresh",
        },
      ],
      bulkActions: [
        {
          title: "Print Certificate Label",
          icon: "print",
          status: 1,
          action: "print_label",
        },
        {
          title: "Mark as Active",
          icon: "mdi-check-all",
          status: 1,
          action: "active",
        },
        {
          title: "Mark as In-Active",
          icon: "mdi-check-all",
          status: 0,
          action: "inactive",
        }
      ],
    };
  },
  components: {
    // PageHeaderCount,
    draggable,
    PageTips,
    Barcode,
    // TableActivity,
    ListingTemplate,
    ListingFooter,
    ListingTable,
    ListingHeader,
    ListingSearchCriteria,
    Dialog,
    Datepicker,
  },
  methods: {
    printLabel(){
      const _this = this;
      const token = JwtService.getToken();
      const rows = _this.selectedRows;
      const selected = rows.join(',');
      const routeData = _this.$router.resolve({name: 'print.label_preview', query: {type: "certificate",token:token,ids:selected}});
      window.open(routeData.href, '_blank');

      /*
      const rows = _this.selectedRows;
      const selected = rows.join(',');
      let downloadURL = process.env.VUE_APP_API_URL + "tagging/print-job-sheet?tagging="+selected;
      downloadURL = new URL(downloadURL);
      const token = JwtService.getToken();
      downloadURL.searchParams.append("token", token);
      this.lodash.map(this.queryParams, function (row, index) {
        downloadURL.searchParams.append(index, row);
      });

      this.lodash.map(this.listingSearch, function (row, index) {
        if (index == "dates") {
          if (row && row[0]) {
            downloadURL.searchParams.append("dates[0]", row[0]);
          }
          if (row && row[1]) {
            downloadURL.searchParams.append("dates[1]", row[1]);
          }
        } else {
          downloadURL.searchParams.append(index, row);
        }
      });

     window.open(downloadURL, "_blank");*/
    },
    updateTable() {
      const _this = this;
      if(_this.defaultColDefs.length){
        for (let index = 0; index < _this.defaultColDefs.length; index++) {
            _this.defaultColDefs[index].order = index+1;
        }
      }
      _this.$store
        .dispatch(PATCH, {
          url: "updateColumns/certificate",
          data: { tables: this.defaultColDefs },
        })
        .then(({ data }) => {
          this.customThead = data;
        });

    },
    getStatusColor(status){
      let color = 'green';
      switch(status){
        case 'active':
         color='green';
        break;
        case 'inactive':
        color= 'red';
        break;
        case 'in-active':
        color= 'red';
        break;
        case 'In Active':
        color= 'red';
        break;
      }
      return color;
    },
    formatedateTime(param) {
      return moment(param).format("hh:mm A");
    },
    partBulkAction(status,action='') {
      const _this = this;
      if(action=='active'||action=='inactive'){
        _this.$store
        .dispatch(PATCH, {
          url: "certificate",
          data: {
            status,
            certificates: _this.selectedRows,
          },
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.selectedRows = [];
          _this.getRows();
          _this.importDialog = false;
        });
      }
      if(action=='print_label'){
        this.dialog=true;
      }
     
    },
    exportQuotation() {
      let downloadURL = process.env.VUE_APP_API_URL + "certificate/export";
      downloadURL = new URL(downloadURL);
      const token = JwtService.getToken();
      downloadURL.searchParams.append("token", token);
      this.lodash.map(this.queryParams, function (row, index) {
        downloadURL.searchParams.append(index, row);
      });

      this.lodash.map(this.listingSearch, function (row, index) {
        if (index == "dates") {
          if (row && row[0]) {
            downloadURL.searchParams.append("dates[0]", row[0]);
          }
          if (row && row[1]) {
            downloadURL.searchParams.append("dates[1]", row[1]);
          }
        } else {
          downloadURL.searchParams.append(index, row);
        }
      });
      window.open(downloadURL, "_blank");
    },
    moreAction(action) {
      const _this = this;
      switch (action) {
        case "import_items":
          _this.importDialog = true;
          break;
        case "export_items":
          _this.bulkProductExport();
          break;
        case "export_current_view":
          _this.exportCurrentView("product");
          break;
        case "refresh_list":
          _this.getRows();
          break;
      }
    },
    handleFileUpload() {
      this.file = this.$refs.file.files[0];
    },
    importRows() {
      // const _this = this;
      const file = this.$refs.file.files[0];
      if (!file) {
        alert("No file chosen");
        return;
      }
      const data = new FormData();
      data.append("excel", file);
      const _this = this;
      _this.$store
        .dispatch(POST, {
          url: "part/import",
          data: data,
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.selectedRows = [];
          _this.getRows();
          _this.importDialog = false;
        });
    },
    bulkProductExport() {
      const _this = this;
      let fileName = new Date();
      _this.exportLoading = true;
      _this.$store
        .dispatch(DOWNLOAD, {
          url: "parts/export",
        })
        .then(({ data }) => {
          saveAs(data, "parts-export-" + fileName.getTime() + ".xlsx");
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.exportLoading = false;
        });
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Certificate",
      },
    ]);

    if (this.getPermission("part:create") == false) {
      let index = this.lodash.findIndex(this.moreActions, {
        action: "import_items",
      });
      if (index >= 0) {
        this.moreActions.splice(index, 1);
      }
    }
  },
  beforeMount() {
    const _this = this;
    _this.lodash.assign(_this.queryParams, _this.$route.query);

    _this.status = _this.$route.query.status || _this.status;

    _this.paginationPageSize =
      window.localStorage.getItem(_this.pageModule) || 50;
  },
};
</script>
